import { render, staticRenderFns } from "./UjoDetail.vue?vue&type=template&id=02182de4&scoped=true"
import script from "./UjoDetail.vue?vue&type=script&lang=js"
export * from "./UjoDetail.vue?vue&type=script&lang=js"
import style0 from "./UjoDetail.vue?vue&type=style&index=0&id=02182de4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02182de4",
  null
  
)

export default component.exports