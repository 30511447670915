w<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TolRouteAdd"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div
            class="d-flex justify-content-between align-items-center flex-wrap grid-margin"
          >
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6
                    class="card-title mb-0 float-start"
                    style="padding-left: 25px"
                  >
                    Tambah Rute Tol
                  </h6>
                  <button
                    class="btn btn-default btn-sm float-end"
                    @click="resetForm"
                    type="button"
                  >
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>
                <div class="card-body">
                  <!--begin::loading-data-->
                  <div v-if="isLoading" class="d-flex align-items-center">
                    <div
                      class="spinner-border ms-auto"
                      role="status"
                      aria-hidden="true"
                    ></div>
                    <strong>{{ $t("loadingTxt") }}...</strong>
                  </div>
                  <!--end::loading-data-->

                  <form
                    class="forms-sample"
                    @submit.prevent="submitData"
                    method="POST"
                  >
                    <div
                      class="d-flex justify-content-between align-items-baseline mb-2"
                    ></div>

                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Nama Rute Tol</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <input
                          type="text"
                          class="form-control"
                          id="nama"
                          :placeholder="'Nama Rute Tol'"
                          v-model="forms.name"
                        />
                        <div v-if="errors.name">
                          <div
                            v-for="error in errors.name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-2">
                        <label
                          for="defaultconfig"
                          class="col-form-label"
                          style="padding-left: 25px"
                          >Rute</label
                        >
                      </div>
                      <div class="col-lg-8">
                        <multiselect
                          @search-change="asyncRoute"
                          v-model="route"
                          :options="fetchRoute"
                          label="route_name"
                          track-by="route_name"
                          placeholede="Please Select Route"
                        >
                          <span slot="noResult">-</span>
                        </multiselect>
                        <div v-if="errors.name">
                          <div
                            v-for="error in errors.name"
                            :key="error"
                            class="alert alert-primary"
                            role="alert"
                          >
                            <i data-feather="alert-circle"></i>
                            {{ error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-4">
                        <div>
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Pool - Loading</label
                          >
                          <button
                            class="btn btn-success btn-xs"
                            style="margin-left: 20px"
                            type="button"
                            @click="addData(tolBerangkat)"
                          >
                            Add
                          </button>
                        </div>
                        <div class="row">
                          <div class="col-md-12 table-responsive">
                            <table
                              class="table table-bordered mb-3"
                              style="min-height: 200px"
                            >
                              <thead>
                                <tr>
                                  <td>Action</td>
                                  <td>Nama Tol</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in tolBerangkat"
                                  :key="index"
                                >
                                  <td>
                                    <a
                                      href="#"
                                      title="Delete"
                                      @click.prevent="
                                        removeField(index, tolBerangkat)
                                      "
                                    >
                                      <i
                                        class="mdi mdi-trash-can-outline"
                                        style="font-size: 16px"
                                      ></i>
                                    </a>
                                  </td>
                                  <td>
                                    <!-- <v-select
                                      :options="fetchGerbangTol"
                                      v-model="item.tol"
                                      label="toll_gate_name"
                                      return-object
                                      @input="
                                        assignTol(index, tolBerangkat, $event)
                                      "
                                      @search="asyncGerbangTol"
                                    ></v-select> -->
                                    <multiselect
                                      @search-change="asyncGerbangTol"
                                      v-model="item.tol"
                                      :options="fetchGerbangTol"
                                      @input="
                                        assignTol(index, tolBerangkat, $event)
                                      "
                                      label="toll_gate_name"
                                      track-by="toll_gate_name"
                                      placeholede="Please Select Gerbang Tol"
                                    >
                                      <span slot="noResult">-</span>
                                    </multiselect>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Loading - Unloading</label
                          >
                          <button
                            class="btn btn-success btn-xs"
                            type="button"
                            style="margin-left: 20px"
                            @click="addData(tolPulang)"
                          >
                            Add
                          </button>
                        </div>
                        <div class="row">
                          <div class="col-md-12 table-responsive">
                            <table
                              class="table table-bordered mb-3"
                              style="min-height: 200px"
                            >
                              <thead>
                                <tr>
                                  <td>Action</td>
                                  <td>Nama Tol</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in tolPulang"
                                  :key="index"
                                >
                                  <td>
                                    <a
                                      href="#"
                                      title="Delete"
                                      @click.prevent="
                                        removeField(index, tolPulang)
                                      "
                                    >
                                      <i
                                        class="mdi mdi-trash-can-outline"
                                        style="font-size: 16px"
                                      ></i>
                                    </a>
                                  </td>
                                  <td>
                                    <multiselect
                                      @search-change="asyncGerbangTol"
                                      v-model="item.tol"
                                      :options="fetchGerbangTol"
                                      @input="
                                        assignTol(index, tolPulang, $event)
                                      "
                                      label="toll_gate_name"
                                      track-by="toll_gate_name"
                                      placeholede="Please Select Gerbang Tol"
                                    >
                                      <span slot="noResult">-</span>
                                    </multiselect>

                                    <!-- <v-select
                                      :options="fetchGerbangTol"
                                      v-model="item.tol"
                                      label="toll_gate_name"
                                      return-object
                                      @input="
                                        assignTol(index, tolPulang, $event)
                                      "
                                      @search="asyncGerbangTol"
                                    ></v-select> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>



                      <div class="col-md-4">
                        <div>
                          <label
                            for="defaultconfig"
                            class="col-form-label"
                            style="padding-left: 25px"
                            >Unloading - Pool</label
                          >
                          <button
                            class="btn btn-success btn-xs"
                            type="button"
                            style="margin-left: 20px"
                            @click="addData(tolNew)"
                          >
                            Add
                          </button>
                        </div>
                        <div class="row">
                          <div class="col-md-12 table-responsive">
                            <table
                              class="table table-bordered mb-3"
                              style="min-height: 200px"
                            >
                              <thead>
                                <tr>
                                  <td>Action</td>
                                  <td>Nama Tol</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in tolNew"
                                  :key="index"
                                >
                                  <td>
                                    <a
                                      href="#"
                                      title="Delete"
                                      @click.prevent="
                                        removeField(index, tolNew)
                                      "
                                    >
                                      <i
                                        class="mdi mdi-trash-can-outline"
                                        style="font-size: 16px"
                                      ></i>
                                    </a>
                                  </td>
                                  <td>
                                    <multiselect
                                      @search-change="asyncGerbangTol"
                                      v-model="item.tol"
                                      :options="fetchGerbangTol"
                                      @input="
                                        assignTol(index, tolNew, $event)
                                      "
                                      label="toll_gate_name"
                                      track-by="toll_gate_name"
                                      placeholede="Please Select Gerbang Tol"
                                    >
                                      <span slot="noResult">-</span>
                                    </multiselect>

                                    <!-- <v-select
                                      :options="fetchGerbangTol"
                                      v-model="item.tol"
                                      label="toll_gate_name"
                                      return-object
                                      @input="
                                        assignTol(index, tolPulang, $event)
                                      "
                                      @search="asyncGerbangTol"
                                    ></v-select> -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="card-footer">
                      <button
                        class="btn btn-warning text-black fw-bolder btn-icon-text"
                        @click="backForm"
                        type="button"
                      >
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        class="btn btn-primary float-end btn-sm"
                        type="submit"
                      >
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <!--end::card-->
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>

<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import multiselect from "vue-multiselect";

export default {
  name: "SystemCodeAdd",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    multiselect: multiselect,
  },
  data() {
    return {
      route: [],
      fetchRoute: [],
      tolBerangkat: [],
      tolPulang: [],
      tolNew: [],
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      closeBtn: true,
      errors: [],
      permision_role: [],
      fetchGerbangTol: [],
      forms: { name: "", usernmae: "" },
    };
  },
  watch: {},
  methods: {
    asyncSearchRoute() {},
    resetForm() {
      this.forms = {};
      this.tolBerangkat = [];
      this.tolPulang = [];
      this.tolNew = [];
      this.route = [];
    },

    backForm() {
      window.location.href = "/tol-route";
    },

    addData(field) {
      field.push({
        tol: "",
      });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    assignTol(index, fieldType, ev) {
      let error = 0;
      fieldType.forEach(function (field) {
        if (field.toll_gate_name == ev.toll_gate_name) {
          error = 1;
        }
      });
      if (error === 0) {
        fieldType[index].rm_toll_gate_master_id = ev.rm_toll_gate_master_id;
        fieldType[index].toll_gate_name = ev.toll_gate_name;
      } else {
        this.removeField(index, fieldType);
        this.error("Duplicate Value !!!");
      }
    },
    submitData() {
      this.fade(true);
      this.forms.departure = this.tolBerangkat;
      this.forms.back = this.tolPulang;
      this.forms.return = this.tolNew;
      this.forms.rm_route_master_id = this.route?.rm_route_master_id;
      // this.forms.company_id = this.company.company_id;
      // this.forms.package_id = this.packageData.package_id;
      // this.forms.commodity_id = this.commodity.commodity_id;

      const baseURI = this.$settings.endPoint + "rm-toll-route/create";
      this.$http
        .post(baseURI, this.forms)
        .then((response) => {
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            //console.log(response.data.datas.user_id);
            // var params = this.$onRandom(response.data.datas.rm_toll_route_id);
            // window.location.href =
            //   "/tol-route/detail/" + params + "?status=200&msg=Successfully";
            // this.success('Berhasil');
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.$router.push("/server-error");
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },
    loadGerbangTol() {
      const baseURI = this.$settings.endPoint + "rm-toll-gate-master";
      this.$http.get(baseURI).then((response) => {
        this.loading();
        this.fetchGerbangTol = response.data.datas.data;
      });
    },
    asyncGerbangTol(ev) {
      const baseURI = this.$settings.endPoint + "rm-toll-gate-master";
      this.$http.get(baseURI + "?toll_gate_name=" + ev).then((response) => {
        // this.loading();
        this.fetchGerbanfetchRoutegTol = response.data.datas.data;
      });
    },
    loadRoute() {
      const baseURI = this.$settings.endPoint + "rm-route-master";
      this.$http.get(baseURI).then((response) => {
        this.loading();
        this.fetchRoute = response.data.datas.data;
      });
    },
    asyncRoute(ev) {
      const baseURI = this.$settings.endPoint + "rm-route-master";
      this.$http.get(baseURI + "?route_name=" + ev).then((response) => {
        // this.loading();
        this.fetchRoute = response.data.datas.data;
      });
    },
    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      this.companyCode = this.detailUser.company_detail.code;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "RmTollRouteController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_create) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "success",
        title: kata,
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  computed: {
    totalBerangkat() {
      return this.tolBerangkat.reduce((acc, item) => acc + item.harga, 0);
    },
    totalPulang() {
      return this.tolPulang.reduce((acc, item) => acc + item.harga, 0);
    },

    totalNew(){
      return this.tolNew.reduce((acc, item) => acc + item.harga, 0);
    },
  },
  events: {},
  created: function () {},
  mounted() {
    this.fetchIt();
    this.loadGerbangTol();
    this.loadRoute();
  },
};
</script>
<style scoped></style>
