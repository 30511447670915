<template>
  <div>
    <div class="main-wrapper">
      <!--begin::sidebar-->
      <sidebar-component classMenu="TruckEdit"></sidebar-component>
      <!--end::sidebar-->

      <div class="page-wrapper">
        <!--begin::navbar-->
        <navbar-component></navbar-component>
        <!--end::navbar-->

        <!--begin::content-->
        <div class="page-content">
          <div class="
              d-flex
              justify-content-between
              align-items-center
              flex-wrap
              grid-margin
            ">
            <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
              <div class="card">
                <div class="card-header">
                  <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                  &nbsp;&nbsp;&nbsp;
                  <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                    {{ $t("truckAccessEdit") }}
                  </h6>
                  <button class="btn btn-default float-end" @click="resetForm" type="button">
                    <i class="link-icon" data-feather="repeat"></i>
                    {{ $t("resetFormTxt") }}
                  </button>
                </div>

                <div class="card-body">
                  <!--begin::loading-data-->
                 
                  <!--end::loading-data-->
                  <form class="forms-sample" @submit.prevent="submitData" method="POST">
                    <div class="
                        d-flex
                        justify-content-between
                        align-items-baseline
                        mb-2
                      "></div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">
                              {{ $t("vehicle_no") }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="vehicle_no" :placeholder="$t('vehicle_no')"
                              v-model="forms.vehicle_no" required />
                            <div v-if="errors.vehicle_no">
                              <div v-for="error in errors.vehicle_no" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("branch_id")
                            }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <v-select :options="fetchBranch" v-model="branch" label="branch_name" return-object
                              ></v-select>

                            <div v-if="errors.branch_id">
                              <div v-for="error in errors.branch_id" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("type_truck")
                            }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <v-select :options="fetchTypeTruck" v-model="typeTruck" label="type_truck" return-object
                              @search="asyncLoadTypeTruck"></v-select>

                            <div v-if="errors.type_truck_id">
                              <div v-for="error in errors.type_truck_id" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("truck_brand")
                            }}</label>
                          </div>
                          <div class="col-lg-8">

                            <v-select :options="fetchTruckBrand" v-model="truckBrand" label="truck_brand_name"
                              return-object></v-select>

                            <div v-if="errors.truck_brand">
                              <div v-for="error in errors.truck_brand" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("truck_model")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="truck_model" :placeholder="$t('truck_model')"
                              v-model="forms.truck_model" />
                            <div v-if="errors.truck_model">
                              <div v-for="error in errors.truck_model" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("truck_status")
                            }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="truck_status" :placeholder="$t('truck_status')"
                              v-model="forms.truck_status" readonly />
                            <div v-if="errors.truck_status">
                              <div v-for="error in errors.truck_status" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("km_limit")
                            }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="km_limit" :placeholder="$t('km_limit')"
                              v-model="forms.km_limit" />
                            <div v-if="errors.km_limit">
                              <div v-for="error in errors.km_limit" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("engine_number")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="engine_number"
                              :placeholder="$t('engine_number')" v-model="forms.engine_number" />
                            <div v-if="errors.engine_number">
                              <div v-for="error in errors.engine_number" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("vin")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="vin" :placeholder="$t('vin')"
                              v-model="forms.vin" />
                            <div v-if="errors.vin">
                              <div v-for="error in errors.vin" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("name_of_owner")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="name_of_owner"
                              :placeholder="$t('name_of_owner')" v-model="forms.name_of_owner" />
                            <div v-if="errors.name_of_owner">
                              <div v-for="error in errors.name_of_owner" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("images_txt")
                            }}</label>
                          </div>
                          <div class="col-lg-8">

                            <div v-if="images_temporary" class="dropify-wrapper has-preview">
                              <div class="dropify-message">
                                <span class="file-icon" v-if="!images_temporary">
                                  <p>Drag and drop a file here or click</p>
                                </span>
                                <p class="dropify-error">Ooops, something wrong appended.</p>
                              </div>
                              <div class="dropify-loader" style="display: none;"></div>
                              <div class="dropify-errors-container">
                                <ul></ul>
                              </div>
                              <input type="file" ref="images" accept="image/*" class="form-control"
                                @change="handleFileUpload($event)">
                              <button type="button" class="dropify-clear" @click="removeImages">Remove</button>
                              <div class="dropify-preview" style="display: block;">
                                <img :src="images_temporary" width="90%" height="80%" />
                                <span class="dropify-render"></span>
                                <div class="dropify-infos">
                                  <div class="dropify-infos-inner">
                                    <p class="dropify-filename">
                                    </p>
                                    <p class="dropify-infos-message">Drag and drop or click to replace</p>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div v-else class="dropify-wrapper">
                              <div class="dropify-message">
                                <span class="file-icon">
                                  <p>Drag and drop a file here or click</p>
                                </span>
                              </div>
                              <div class="dropify-loader" style="display: none;"></div>
                              <div class="dropify-errors-container">
                                <ul></ul>
                              </div>
                              <input type="file" ref="images" accept="image/*" class="form-control"
                                @change="handleFileUpload($event)">
                              <button type="button" class="dropify-clear">Remove</button>
                              <div class="dropify-preview" style="display: none;">
                                <img :src="images_temporary" width="90%" height="80%" />
                                <span class="dropify-render"></span>
                                <div class="dropify-infos">
                                  <div class="dropify-infos-inner">
                                    <p class="dropify-filename">
                                    </p>
                                    <p class="dropify-infos-message">Drag and drop or click to replace</p>
                                  </div>
                                </div>
                              </div>

                            </div>


                            <div v-if="errors.images">
                              <div v-for="error in errors.images" :key="error" class="alert alert-primary" role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("last_km_service") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="last_km_service"
                              :placeholder="$t('last_km_service')"
                              v-model="forms.last_km_service"
                            />
                            <div v-if="errors.last_km_service">
                              <div
                                v-for="error in errors.last_km_service"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->

                      </div>

                      <div class="col-lg-6">
                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">
                              {{ $t("device_id") }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="vehicle_no" :placeholder="$t('device_id')"
                              v-model="forms.device_id" required />
                            <div v-if="errors.device_id">
                              <div v-for="error in errors.device_id" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("ownership")
                            }}</label>
                          </div>
                          <div class="col-lg-8">

                            <v-select :options="fetchOwnersShip" v-model="ownersShip" label="name"
                              return-object></v-select>

                            <div v-if="errors.ownership">
                              <div v-for="error in errors.ownership" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("carrier")
                            }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <!--input
                              type="text"
                              class="form-control"
                              id="truck_model"
                              :placeholder="$t('carrier')"
                              v-model="forms.carrier"
                            /-->
                            <v-select :options="fetchCarrier" 
                            v-model="forms.carrier" 
                            return-object></v-select>
                            <div v-if="errors.carrier">
                              <div v-for="error in errors.carrier" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("gps_vendor")
                            }}*</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="gps_vendor" :placeholder="$t('gps_vendor')"
                              v-model="forms.gps_vendor" />
                            <div v-if="errors.gps_vendor">
                              <div v-for="error in errors.gps_vendor" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("manufacture_year")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <Datepicker v-model="manufacture_year" :bootstrap-styling=true format="yyyy"
                              placeholder="Please Select Year.." :minimumView="'year'" :maximumView="'year'"
                              :initialView="'year'"></Datepicker>
                            <div v-if="errors.manufacture_year">
                              <div v-for="error in errors.manufacture_year" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("purchased_year")
                            }}</label>
                          </div>
                          <div class="col-lg-8">

                            <Datepicker v-model="purchased_year" :bootstrap-styling=true format="yyyy"
                              placeholder="Please Select Year.." :minimumView="'year'" :maximumView="'year'"
                              :initialView="'year'"></Datepicker>

                            <div v-if="errors.purchased_year">
                              <div v-for="error in errors.purchased_year" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("ac_category")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="ac_category" :placeholder="$t('ac_category')"
                              v-model="forms.ac_category" />
                            <div v-if="errors.ac_category">
                              <div v-for="error in errors.ac_category" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("box_category")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="box_category" :placeholder="$t('box_category')"
                              v-model="forms.box_category" />
                            <div v-if="errors.box_category">
                              <div v-for="error in errors.box_category" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("project")
                            }}</label>
                          </div>
                          <div class="col-lg-8">
                            <!-- <input type="text" class="form-control" id="project" :placeholder="$t('project')"
                              v-model="forms.project" /> -->

                             
                              <multiselect v-model="company" :options="fetchCompany" @search-change="asyncSearchCompany"
                              :custom-label="customLabel" placeholder="Type to search" track-by="name"
                              label="code"><span slot="noResult">Oops! No elements found. Consider changing the
                                search query.</span></multiselect>

                            <div v-if="errors.project">
                              <div v-for="error in errors.project" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">{{
                              $t("unit_allocation")
                            }}*</label>
                          </div>
                          <div class="col-lg-8">

                            <v-select :options="fetchUnitAllocation" v-model="unitAllocation"
                              label="name" return-object></v-select>

                            <div v-if="errors.unit_allocation">
                              <div v-for="error in errors.unit_allocation" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>

                          </div>
                        </div>

                        <!--div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("unit_allocation") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="unit_allocation"
                              :placeholder="$t('unit_allocation')"
                              v-model="forms.unit_allocation"
                            />
                            <div v-if="errors.unit_allocation">
                              <div
                                v-for="error in errors.unit_allocation"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div-->


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Billable
                              Method*</label>
                          </div>
                          <div class="col-lg-8">

                            <v-select :options="fetchBillable" v-model="billable" label="billable_name"
                              return-object></v-select>

                            <div v-if="errors.billable">
                              <div v-for="error in errors.billable" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Driver
                              Name</label>
                          </div>
                          <div class="col-lg-8">

                            <v-select :options="fetchDriver" v-model="driver" label="driver_name" return-object
                              @search="asyncLoadDriver"></v-select>

                            <div v-if="errors.driver_id">
                              <div v-for="error in errors.driver_id" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>

                          </div>
                        </div>


                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Truck
                              Category</label>
                          </div>
                          <div class="col-lg-8">

                            <v-select :options="fetchTruckCategory" v-model="truck_category" label="name"
                              return-object></v-select>

                            <div v-if="errors.driver_id">
                              <div v-for="error in errors.driver_id" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>

                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Target
                              Trip</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="target_trip" v-model="forms.target_trip" />
                            <div v-if="errors.target_trip">
                              <div v-for="error in errors.target_trip" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <div class="col-lg-4">
                            <label for="defaultconfig" class="col-form-label" style="padding-left: 25px">Target
                              Revenue</label>
                          </div>
                          <div class="col-lg-8">
                            <input type="text" class="form-control" id="target_revenue"
                              v-model="forms.target_revenue" />
                            <div v-if="errors.target_revenue">
                              <div v-for="error in errors.target_revenue" :key="error" class="alert alert-primary"
                                role="alert">
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div>



                        <!-- <div class="row mb-3">
                          <div class="col-lg-4">
                            <label
                              for="defaultconfig"
                              class="col-form-label"
                              style="padding-left: 25px"
                              >{{ $t("last_service") }}</label
                            >
                          </div>
                          <div class="col-lg-8">
                            <input
                              type="text"
                              class="form-control"
                              id="last_service"
                              :placeholder="$t('last_service')"
                              v-model="forms.last_service"
                            />
                            <div v-if="errors.last_service">
                              <div
                                v-for="error in errors.last_service"
                                :key="error"
                                class="alert alert-primary"
                                role="alert"
                              >
                                <i data-feather="alert-circle"></i>
                                {{ error }}
                              </div>
                            </div>
                          </div>
                        </div> -->

                      </div>
                    </div>

                    <div class="card-footer">
                      <button class="
                          btn btn-warning
                          text-black
                          fw-bolder
                          btn-icon-text
                        " @click="backForm" type="button">
                        <i class="link-icon" data-feather="arrow-left"></i>
                        {{ $t("backMess") }}
                      </button>

                      &nbsp;&nbsp;&nbsp;
                      <button v-if="!isLoading"  class="btn btn-primary float-end btn-sm" type="submit">
                        <i class="link-icon" data-feather="save"></i>
                        {{ $t("submitFormTxt") }}
                      </button>

                      <div v-if="isLoading" class="d-flex align-items-center">
                        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                        <strong>{{ $t("loadingTxt") }}...</strong>
                      </div>

                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::content-->

        <!--begin::footer-->
        <footer-component></footer-component>
        <!--end::footer-->
      </div>
    </div>
  </div>
</template>


<script>
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import multiselect from "vue-multiselect";

export default {
  name: "TruckEdit",
  props: {},
  components: {
    "sidebar-component": sidebarComponent,
    "navbar-component": navbarComponent,
    "footer-component": footerComponent,
    Datepicker,
    multiselect:multiselect
  },
  data() {
    return {
      isLoading: false,
      maxToasts: 100,
      position: "up right",
      permision_role: [],
      fetchBranch: [],
      branch: [],
      closeBtn: true,
      errors: [],
      fetchCompany:[],
      company:'',
      manufacture_year: '',
      purchased_year: '',
      fetchCarrier: [],
      fetchTruckBrand: [],
      fetchBillable: [],
      fetchUnitAllocation: [],
      fetchOwnersShip: [],
      fetchTypeTruck: [],
      fetchDriver: [],
      driver: [],
      fetchTruckCategory: [],
      truck_category: "",
      truckBrand: [],
      typeTruck: [],
      billable: [],
      unitAllocation: [],
      ownersShip: [],
      userData: "",
      companyCode: "",
      forms: [],
      images: '',
      images_temporary: null,
    };
  },
  watch: {},
  methods: {
    removeImages() {
      this.$refs.images.value = "";
      this.images_temporary = null;
    },

    handleFileUpload(event) {
      this.images = event.target.files[0];
      this.images_temporary = URL.createObjectURL(this.images);
    },

    resetForm() {
      this.removeImages();
      this.forms = "";
    },

    backForm() {
      window.location.href = "/truck";
    },

    loadData(id) {
      const baseURI = this.$settings.endPointCt + "master-truck/detail/" + id;
      return this.$http.get(baseURI).then((response) => {
        this.forms = response.data.datas;
        this.branch = response.data.datas.branch;
        this.typeTruck = response.data.datas.type_truck;
        this.images_temporary = response.data.datas.images;
        this.driver = response.data.datas.driver;
        this.manufacture_year = response.data.datas.manufacture_year + '-01-01';
        this.purchased_year = response.data.datas.purchased_year + '-01-01';
        this.billable = response.data.datas.billable;

        this.truck_category = response.data.datas.truck_category;

        this.truckBrand = response.data.datas.truck_brand;

        this.unitAllocation = response.data.datas.unit_allocation,

        this.company={
          code:'',
          company_id:this.forms.customer_id,
          name:this.forms.project,
        }


        this.ownersShip =response.data.datas.ownership;
        // this.form={ name: 'asep', usernmae:res.username };
      });
    },

    fetchIt() {
      const userDatas = this.$getUserInfo();
      this.detailUser = userDatas.sub;
      // this.permision_role = userDatas.sub.permision_role;
      let rolePermision = [];
      userDatas.sub.permision_role.forEach(function (permision) {
        if (permision.permission.controller == "MasterTruckController") {
          rolePermision = permision;
          // console.log(permision);
        }
      });

      if (!rolePermision.method_update) {
        this.$router.push("/authorized-error");
      }

      this.permision_role = rolePermision;
    },


    loadCompany() {
      const baseURI = this.$settings.endPoint + "company/dropdown-company";

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },


    asyncSearchCompany(ev) {
      const baseURI = this.$settings.endPoint + "company/dropdown-company?code=" + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCompany = response.data.datas.data;
      });
    },

    loadBranch() {
      const baseURI =
        this.$settings.endPointCt +
        `branch/select`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    loadBrand() {
      const baseURI = this.$settings.endPointCt + `master-lookup?unique_id=trMerk`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckBrand = response.data.datas;  
      });      
    },



    asyncLoadBranch(ev) {
      const baseURI =
        this.$settings.endPointCt +
        `branch?branch_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBranch = response.data.datas.data;
      });
    },

    loadTypeTruck() {
      const baseURI = this.$settings.endPointCt + `type-truck`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },


    asyncLoadTypeTruck(ev) {
      const baseURI = this.$settings.endPointCt + `type-truck?type_truck=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTypeTruck = response.data.datas.data;
      });
    },


    loadCategoryTruck() {
      const baseURI = this.$settings.endPointCt + `master-lookup?unique_id=truca`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchTruckCategory = response.data.datas;
      });
    },

    submitData() {
      this.isLoading=true;
      this.fade(true);
      // let params = this.$onBehind(this.$route.params.id);
      // let formData = new FormData();
      // formData.append("name", this.forms.name);
      // formData.append("username", this.forms.username);
      // formData.append("password", this.forms.password);
      // formData.append("email", this.forms.email);
      // formData.append("company_id", this.forms.company_id);
      // formData.append("role_id", this.forms.role_id);
      // formData.append("status", 'active');
      // console.log(this.typeTruck);
      this.forms.branch_id = this.branch.branch_id;
      this.forms.type_truck_id = this.typeTruck.type_truck_id;


      let formData = new FormData();

      formData.append("images", this.images);
      formData.append("branch_id", this.forms.branch_id);
      formData.append("type_truck_id", this.forms.type_truck_id);
      formData.append("vehicle_no", this.forms.vehicle_no);
      formData.append("truck_brand", this.truckBrand);
      formData.append("truck_model", this.forms.truck_model);
      formData.append("truck_status", this.forms.truck_status);
      formData.append("km_limit", this.forms.km_limit);
      formData.append("engine_number", this.forms.engine_number);
      formData.append("vin", this.forms.vin);
      formData.append("ownership", this.ownersShip);
      formData.append("name_of_owner", this.forms.name_of_owner);
      //formData.append("last_km_service", this.forms.last_km_service);
      formData.append("device_id", this.forms.device_id);
      // formData.append("ownership", this.forms.ownership);
      formData.append("carrier", this.forms.carrier);
      formData.append("gps_vendor", this.forms.gps_vendor);
      formData.append("manufacture_year", moment(String(this.manufacture_year)).format('YYYY'));
      formData.append("purchased_year", moment(String(this.purchased_year)).format('YYYY'));
      formData.append("ac_category", this.forms.ac_category);
      formData.append("box_category", this.forms.box_category);
      formData.append("project", this.company?.name);
      formData.append("customer_id", this.company?.company_id);
      formData.append("unit_allocation", this.unitAllocation);
      if (this.driver) {
        formData.append("driver_id", this.driver.driver_id);
      }
      formData.append("billable", this.billable);
      formData.append("truck_category", this.truck_category);
      formData.append("target_revenue", this.forms.target_revenue);
      formData.append("target_trip", this.forms.target_trip);
      //formData.append("last_service", this.forms.last_service);

      const baseURI =
        this.$settings.endPointCt + "master-truck/update/" + this.forms.master_truck_id;
      this.$http
        .post(baseURI, formData)
        .then((response) => {
          this.isLoading=false;
          this.loading();
          if (response.data.status === 200) {
            //this.resetForm();
            this.errors = [];
            this.success(response.data.datas.messages);

            //window.history.back();
            var params = this.$onRandom(this.forms.master_truck_id);
            window.location.href = "/truck/detail/" + params + "?status=200&msg=Successfully";
          } else {
            this.errors = response.data.errors;
            this.resultError(response.data.errors);
          }
        })
        .catch((error) => {
          this.isLoading=false;
          this.loading();
          if (error.response) {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
              this.resultError(error.response.data.errors);
            } else if (error.response.status === 500) {
              this.error(error.response.data.errors.message);
            } else {
              this.$router.push("/page-not-found");
            }
          }
          //this.resetForm();
        });
    },

    fade(sType) {
      this.isLoading = sType;
    },

    loadDriver() {
      const baseURI = this.$settings.endPointCt + `master-driver`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;
      });
    },

    loadCarrier() {
      const baseURI = this.$settings.endPointCt + `master-lookup?unique_id=carer`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchCarrier = response.data.datas;
        
      },
      )
    },

    asyncLoadDriver(ev) {
      const baseURI = this.$settings.endPointCt + `master-driver?driver_name=` + ev;

      return this.$http.get(baseURI).then((response) => {
        this.fetchDriver = response.data.datas.data;

      });
    },

    loading() {
      this.fade(true);
      setTimeout(() => {
        this.fade(false);
      }, 1000); // hide the message after 3 seconds
    },

    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },

    loadCBillabel() {
      const baseURI = this.$settings.endPointCt + `master-lookup?unique_id=bunit`;

      return this.$http.get(baseURI).then((response) => {
        this.fetchBillable = response.data.datas;  
      });      
    },

    success(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
        // onShown: function() {
        //     console.log('asdasdasd');
        //     //window.location.href = '/';
        // }
      });

      Toast.fire({
        icon: "success",
        title: kata,
      });
    },


    customLabel(option) {
      // Sesuaikan label berdasarkan kombinasi code dan name
      return `${option.code} - ${option.name}`;
    },



    loadUnitAlocation() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=tralloc`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchUnitAllocation = response.data.datas;
      });
    },

    loadOwnerShip() {
      const baseURI =
        this.$settings.endPointCt + `master-lookup?unique_id=trowner`;
      return this.$http.get(baseURI).then((response) => {
        this.fetchOwnersShip = response.data.datas;
      });
    },

    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
  },
  events: {},
  created: function () {
    this.loadBranch();
  },
  mounted() {
    var params = this.$onBehind(this.$route.params.id);
    this.loadData(params[0]);
    this.fetchIt();
    this.loadTypeTruck();
    this.loadCategoryTruck();

    this.loadDriver();
    this.loadCarrier();
    this.loadCBillabel();
    this.loadCompany();
    this.loadBrand();
    this.loadUnitAlocation()
    this.loadOwnerShip()

    // this.fetchBillable.push(
    //   {
    //     billable_id: "1",
    //     billable_name: "LOGISTIK",
    //   },
    //   {
    //     billable_id: "2",
    //     billable_name: "RENTAL",
    //   },
    // );

    

  },
};
</script>
<style scoped>

</style>