<template>
    <div>
        <div class="main-wrapper">
            <!--begin::sidebar-->
            <sidebar-component classMenu="BbmAdd"></sidebar-component>
            <!--end::sidebar-->

            <div class="page-wrapper">
                <!--begin::navbar-->
                <navbar-component></navbar-component>
                <!--end::navbar-->

                <!--begin::content-->
                <div class="page-content">
                    <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
                        <div class="col-lg-12 col-xl-12 col-sm-12 col-xs-12 col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <!-- <i class="link-icon float-start" data-feather="arrow-left"></i> -->
                                    &nbsp;&nbsp;&nbsp;
                                    <h6 class="card-title mb-0 float-start" style="padding-left: 25px">
                                        POD Form {{ formEdit ? 'Edit' :'' }} {{ formView ? 'Detail' :'' }}
                                    </h6>
                                    <button class="btn btn-default btn-sm float-end" @click="resetForm" type="button">
                                        <i class="link-icon" data-feather="repeat"></i>
                                        {{ $t("resetFormTxt") }}
                                    </button>
                                </div>
                                <div class="card-body">
                                    <!--begin::loading-data-->
                                    
                                    <!--end::loading-data-->

                                    <form class="forms-sample" @submit.prevent="submitData" method="POST">
                                        <div class="d-flex justify-content-between align-items-baseline mb-2"></div>

                                        <div class="row mb-3">
                                            <div class="col-lg-6">
                                                <label for="load_id" class="col-form-label">Load Id</label>
                                                <input type="text" v-if="formView" class="form-control" id="company_code" v-model="forms.load_id" placeholder="load id" readonly disabled/>
                                                <v-select v-if="!formView" :options="fetchLoadId" v-model="load_id" label="load_id"
                                                    return-object @input="getData" @search="asyncSearchLoadId">
                                                </v-select>

                                                <!-- <multiselect v-if="!formView"  v-model="load_id" :options="fetchLoadId" @search-change="asyncSearchLoadId"
                                                placeholder="Type to search" track-by="load_id" @input="getData" label="load_id"></multiselect> -->

                                                <div v-if="errors.load_id">
                                                    <div v-for="error in errors.load_id" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label for="company" class="col-form-label">Company</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control" id="company_code" v-model="forms.company_code" placeholder="Code" disabled/>
                                                    <input type="text" class="form-control" id="company_name" v-model="forms.company_name" placeholder="Name" disabled/>
                                                
                                                </div>
                                                <div v-if="errors.company_code || errors.company_name">
                                                    <div v-for="error in [...(errors.company_code || []), ...(errors.company_name || [])]" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-3">
                                                <label for="pickup_plan" class="col-form-label">Pickup Plan </label>
                                                
                                                <input type="text" class="form-control" id="pickup_plan"
                                                    v-model="forms.pick_plan" readonly
                                                    required />
                                                <div v-if="errors.pick_plan">
                                                    <div v-for="error in errors.pick_plan" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <label for="vehicle_no" class="col-form-label">Vehicle No</label>
                                                <input type="text" class="form-control" id="vehicle_no"
                                                    v-model="forms.vehicle_no" 
                                                    readonly />
                                                <div v-if="errors.vehicle_no">
                                                    <div v-for="error in errors.vehicle_no" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-3">
                                                <label for="vehicle_no" class="col-form-label">Driver NMK</label>
                                                <input type="text" class="form-control" id="vehicle_no"
                                                    v-model="forms.driver_nmk" 
                                                    readonly />
                                                <div v-if="errors.driver_nmk">
                                                    <div v-for="error in errors.driver_nmk" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <label for="vehicle_no" class="col-form-label">Driver Name</label>
                                                <input type="text" class="form-control" id="vehicle_no"
                                                    v-model="forms.driver_name" 
                                                    readonly />
                                                <div v-if="errors.driver_name">
                                                    <div v-for="error in errors.driver_name" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                        <div class="row mb-3">
                                            <div class="col-lg-6">
                                                <label for="pickup_plan" class="col-form-label">Origin </label>
                                                <input type="text" class="form-control" id="origin"
                                                    v-model="forms.pick_name" readonly
                                                    required />
                                                <div v-if="errors.pick_name">
                                                    <div v-for="error in errors.pick_name" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <label for="dest_name" class="col-form-label">Destination</label>
                                                <input type="text" class="form-control" id="dest_name"
                                                    v-model="forms.dest_name" 
                                                    readonly />
                                                <div v-if="errors.dest_name">
                                                    <div v-for="error in errors.dest_name" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>


                                          


                                          

                                          

                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-lg-4">
                                                <label for="defaultconfig" class="col-form-label">Unload Date</label>
                                                <input type="text" v-if="formView" class="form-control" id="company_code" v-model="unloadDate.startDate" placeholder="Unload Date" readonly disabled/>
                                                <date-range-picker v-if="!formView" style="width: 100%" :locale-data="locale"
                                                    v-model="unloadDate" :opens="opens" :singleDatePicker="true"
                                                    :timePicker24Hour="true" @change="handleDateChange()" :timePicker="false">
                                                </date-range-picker>
                                                <div v-if="errors.unload_date">
                                                    <div v-for="error in errors.unloadDate" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <label for="defaultconfig" class="col-form-label">Doc Return Date</label>
                                                <input type="text" v-if="formView" class="form-control" id="company_code" v-model="docReturnDate.startDate" placeholder="Doc Return Date" readonly disabled/>
                                                <date-range-picker v-if="!formView" style="width: 100%" :locale-data="locale"
                                                    v-model="docReturnDate" :opens="opens" :singleDatePicker="true"
                                                    :timePicker24Hour="true" :timePicker="false">
                                                </date-range-picker>
                                                <div v-if="errors.doc_return_date">
                                                    <div v-for="error in errors.doc_return_date" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-4">
                                                <label for="defaultconfig" class="col-form-label">Doc Send Date</label>
                                                <input type="text" v-if="formView" class="form-control" id="company_code" v-model="docSendDate.startDate" placeholder="Doc Return Date" readonly disabled/>
                                                <date-range-picker v-if="!formView" style="width: 100%" :locale-data="locale"
                                                    v-model="docSendDate" :opens="opens" :singleDatePicker="true"
                                                    :timePicker24Hour="true" :timePicker="false"  @update:model-value="handleDateChange">
                                                </date-range-picker>
                                                <div v-if="errors.doc_send_date">
                                                    <div v-for="error in errors.doc_send_date" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div class="row mb-3">
                                           


                                            <div class="col-lg-6">
                                                <label for="dest_name" class="col-form-label">Lead Time POD {{ forms.leadtime_pod }}</label>
                                                <input type="text" class="form-control" id="lead_time_pod"
                                                    v-model="forms.leadtime_pod" 
                                                    readonly />
                                                <div v-if="errors.lead_time_pod">
                                                    <div v-for="error in errors.lead_time_pod" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-6">
                                                <label for="dest_name" class="col-form-label">Archievement</label>
                                                <input type="text" class="form-control" id="archievement"
                                                    v-model="forms.archivement" 
                                                    readonly />
                                                <div v-if="errors.archivement">
                                                    <div v-for="error in errors.archivement" :key="error"
                                                        class="alert alert-primary" role="alert">
                                                        <i data-feather="alert-circle"></i>
                                                        {{ error }}
                                                    </div>
                                                </div>
                                            </div>



                                          

                                          

                                        </div>

                                        <div class="card-footer">
                                            <button class="btn btn-warning text-black fw-bolder btn-icon-text"
                                                @click="backForm" type="button">
                                                <i class="link-icon" data-feather="arrow-left"></i>
                                                {{ $t("backMess") }}
                                            </button>

                                            &nbsp;&nbsp;&nbsp;
                                            <button v-if="!isLoading && !formView" class="btn btn-primary float-end btn-sm" type="submit">
                                                <i class="link-icon" data-feather="save"></i>
                                                {{ $t("submitFormTxt") }}
                                            </button>

                                            <div v-if="isLoading" class="d-flex align-items-center">
                                                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                                <strong>{{ $t("loadingTxt") }}...</strong>
                                            </div>

                                            <!-- <button
                          disabled
                          class="btn btn-success float-end btn-sm"
                          style="margin-right: 5px"
                          @click="calculate()"
                        >
                          <i class="mdi mdi-calculator"></i>
                          Calculate
                        </button> -->
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!--end::card-->
                        </div>
                    </div>
                </div>
                <!--end::content-->

                <!--begin::footer-->
                <footer-component></footer-component>
                <!--end::footer-->
            </div>
        </div>
    </div>
</template>

<script>
// import multiselect from "vue-multiselect";
import sidebarComponent from "@/components/_partials/_sidebar";
import navbarComponent from "@/components/_partials/_navbar";
import footerComponent from "@/components/_partials/_footer";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
    name: "GerbangTolAdd",
    props: {},
    components: {
        "sidebar-component": sidebarComponent,
        "navbar-component": navbarComponent,
        "footer-component": footerComponent,
        DateRangePicker,
        // multiselect:multiselect
    },
    data() {
        return {
            load_id: "",
            fetchLoadId: [],
            locale: {
                format: "dd mmm yyyy",
            },

            unloadDate: {
                startDate: null,
                endDate: null,
            },

            docReturnDate: {
                startDate: null,
                endDate: null,
            },

            docSendDate: {
                startDate: null,
                endDate: null,
            },

            effectiveDate: {
                startDate: null,
                endDate: null,
            },
            expiredDate: {
                startDate: null,
                endDate: null,
            },
            opens: "center",
            permision_role: [],
            isLoading: false,
            maxToasts: 100,
            position: "up right",
            closeBtn: true,
            errors: [],
            userData: "",
            companyCode: "",
            fetchCompany: [],
            fetchCompanyNotLinc: [],
            forms: { leadtime_pod:'',},
            company: [],
            formView:false,
            formEdit:false,
        
        };
    },
    watch: {
    docSendDate(newDate) {
        this.handleDateChange(newDate);
    }
    },
    methods: {
        resetForm() {
          this.forms="";
        },


        loadDataLoadId() {
    
            const baseURI = this.$settings.endPoint + "transport-order-header-new/select-load-id";

            return this.$http.get(baseURI).then((response) => {
                this.fetchLoadId = response.data.datas.data;
            });
        },

        asyncSearchLoadId(ev) {
            const baseURI = this.$settings.endPoint + "transport-order-header-new/select-load-id?load_id=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchLoadId = response.data.datas.data;
            });
        },

        loadCompany() {
            const baseURI = this.$settings.endPoint + "company?limit=10";

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;
            });
        },

        loadCompanyNotLinc() {
            const baseURI = this.$settings.endPoint + `company/select`;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },

        handleDateChange(newDate) {
        // Set docSendDate dari newDate
        this.docSendDate = newDate;

        // Cek jika `docReturnDate` dan `unloadDate` ada sebelum melanjutkan perhitungan
      
        if (!this.docSendDate || !this.unloadDate) {
            console.log("Tanggal unload atau return belum lengkap, perhitungan tidak dilakukan.");
            return;
        }

        // Menggunakan moment untuk memformat tanggal
        const docSendDate = moment(this.docSendDate.startDate, "YYYY-MM-DD");
        const unloadDate = moment(this.unloadDate.startDate, "YYYY-MM-DD");

        // Periksa apakah tanggal valid
        if (!docSendDate.isValid || !unloadDate.isValid()) {
            console.log("Format tanggal tidak valid.");
            return;
        }

        // Hitung selisih hari antara `docReturnDate` dan `unloadDate`
        const differenceInDays = docSendDate.diff(unloadDate, 'days');

        // Pastikan leadtime_pod adalah tanggal dan valid
        // const leadtimePodDate = moment(this.lead_time_pod, "YYYY-MM-DD");
        // if (!leadtimePodDate.isValid()) {
        //     console.log("Leadtime POD tidak valid.");
        //     return;
        // }

        // Hitung apakah selisihnya HIT atau MISS
        if (differenceInDays <= this.forms.leadtime_pod) {
            console.log("HIT");
            // Set status sebagai HIT
            this.forms.archivement = "HIT";
        } else {
            console.log("MISS");
            // Set status sebagai MISS
            this.forms.archivement = "MISS";
        }
        },


        getData(ev){
            this.forms.leadtime_pod='';
            this.forms.company_code=ev.company_code;
            this.forms.company_name=ev.company_name;
            this.forms.pick_plan=ev.pick_plan;
            this.forms.vehicle_no=ev.vehicle_no;
            this.forms.driver_name=ev.driver_name;
            this.forms.driver_nmk=ev.driver_id;
            this.forms.pick_name=ev.pick_name;
            this.forms.dest_name=ev.dest_name;

                this.docReturnDate={
                    startDate:ev.doc_return_date,
                    endDate:ev.doc_return_date,
                };

                this.docSendDate={
                    startDate:ev.doc_send_date,
                    endDate:ev.doc_send_date,
                };


                this.unloadDate={
                    startDate:ev.unload_date,
                    endDate:ev.unload_date,
                };


            this.forms.transport_order_header_id=ev.transport_order_header_id
            this.cekLeadTime(ev.load_id);

        },

        asyncSearchCompany(ev) {
            const baseURI = this.$settings.endPoint + "company?code=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompany = response.data.datas.data;
            });
        },

        cekLeadTime(load_id) {
           
            const baseURI = this.$settings.endPoint + "pod/leadtime-pod/"+load_id

            return this.$http.get(baseURI).then((response) => {
                this.forms.leadtime_pod=response.data.datas;
                console.log(this.forms.leadtime_pod);
            });
        },

        asyncSearchCompanyNotLinc(ev) {
            const baseURI = this.$settings.endPoint + "company/select/code=" + ev;

            return this.$http.get(baseURI).then((response) => {
                this.fetchCompanyNotLinc = response.data.datas.data;
            });
        },

        fetchIt() {
            const userDatas = this.$getUserInfo();
            this.detailUser = userDatas.sub;
            this.companyCode = this.detailUser.company_detail.code;
            // this.permision_role = userDatas.sub.permision_role;
            let rolePermision = [];
            userDatas.sub.permision_role.forEach(function (permision) {
                if (permision.permission.controller == "PODController") {
                    rolePermision = permision;
                    // console.log(permision);
                }
            });

            this.permision_role = rolePermision;
        },

        backForm() {
            window.location.href = "/ct-orders/pod";
        },

        submitData() {
            console.log(this.forms);
            // this.forms.company_id = this.company.company_id;
            this.forms.unload_date = moment(this.unloadDate.startDate).format(
                "YYYY-MM-DD"
            );
            this.forms.doc_return_date = moment(this.docReturnDate.startDate).format(
                "YYYY-MM-DD"
            );

            this.forms.doc_send_date = moment(this.docSendDate.startDate).format(
                "YYYY-MM-DD"
            );
            this.fade(true);
            const baseURI = this.$settings.endPoint + "pod/update/"+this.forms.transport_order_header_id;
            this.$http
                .patch(baseURI, { 
                    "unload_date": this.forms.unload_date,
                    "doc_return_date": this.forms.doc_return_date, 
                    "doc_send_date": this.forms.doc_send_date, 
                    "epod_date": "", 
                    "leadtime_epod":"",                   
                    "leadtime_pod": this.forms.leadtime_pod, 
                    "pod_achievement": this.forms.archivement 
                })
                .then((response) => {
                    this.loading();
                    if (response.data.status === 200) {
                        //this.resetForm();
                        this.errors = [];
                        //console.log(response.data.datas.user_id);
                        var params = this.$onRandom(this.forms.transport_order_header_id);
                        window.location.href =
                            "/ct-orders/pod-form-view/"+params
                        this.success('Berhasil');
                    } else {
                        this.errors = response.data.errors;
                        this.resultError(response.data.errors);
                    }
                })
                .catch((error) => {
                    this.loading();
                    if (error.response) {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors;
                            if (this.errors.message) {
                                this.error(this.errors.message);
                            } else {
                                this.resultError(error.response.data.errors);
                            }
                        } else if (error.response.status === 500) {
                            this.error(error.response.data.errors.message);
                        } else {
                            this.$router.push("/page-not-found");
                        }
                    }
                    //this.resetForm();
                });
        },

        fade(sType) {
            this.isLoading = sType;
        },

        loading() {
            this.fade(true);
            setTimeout(() => {
                this.fade(false);
            }, 1000); // hide the message after 3 seconds
        },

        resultError(data) {
            var count = Object.keys(data).length;
            for (var x = 0; x < count; x++) {
                var nameOb = Object.keys(data)[x];
                var objectData = data[nameOb];
                for (var y = 0; y < objectData.length; y++) {
                    this.error(objectData[y]);
                }
            }
        },

        calculate() {
            this.$swal({
                title: "Apakah Anda Yakin akan mengkalkulasi BBM ?",
                text: "Semua Record Payable dan Billable akan mengalami kenaikan sesuai dengan exponensial kenaikan harga BBM ini",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes!",
            }).then((result) => {
                console.log(result);
            });
        },

        success(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "success",
                title: kata,
            });
        },

        error(kata) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                },
            });
            Toast.fire({
                icon: "error",
                title: kata,
            });
        },

        loadData(id,form) {
            
            if(form=='PodFormView'){
                this.formView=true;
            }

            if(form=='PodFormUpdate'){
                this.formEdit=true;
            }
            
            
            
            const baseURI = this.$settings.endPoint + "pod/detail/" + id;
            return this.$http.get(baseURI).then((response) => {
                this.load_id=response.data.datas;
                this.forms = response.data.datas;
                this.docReturnDate.startDate=this.forms.doc_return_date;

                this.docReturnDate={
                    startDate:this.forms.doc_return_date,
                    endDate:this.forms.doc_return_date,
                };

                this.docSendDate={
                    startDate:this.forms.doc_send_date,
                    endDate:this.forms.doc_send_date,
                };


                this.unloadDate={
                    startDate:this.forms.unload_date,
                    endDate:this.forms.unload_date,
                };


                
                //this.unloadDate.startDate=this.forms.unload_date;
                this.forms.company_code=this.forms.company.code;
                this.forms.company_name=this.forms.company.name;
                this.forms.archivement=this.forms.pod_achievement;
            });
        },


    },
    events: {},
    created: function () { },
    mounted() {
        this.fetchIt();
        this.loadDataLoadId();
        var params = this.$onBehind(this.$route.params.id);
      
        if(params.length!=0){
            console.log(this.$route.name);
            this.loadData(params[0],this.$route.name);
        }

       
        // this.loadCompany();
        // this.loadCompanyNotLinc();
    },
};
</script>
<style scoped></style>