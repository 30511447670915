import { render, staticRenderFns } from "./RasioDetail.vue?vue&type=template&id=df0e8a1e&scoped=true"
import script from "./RasioDetail.vue?vue&type=script&lang=js"
export * from "./RasioDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df0e8a1e",
  null
  
)

export default component.exports